import { useMessagingContext } from "@/context/messagingContext";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";

export default function Home() {
  const router = useRouter();
  const messagingContext = useMessagingContext();
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const mockSubmit = async () => {
    setLoading(true);
    const response = await fetch("/api/add_to_mailing_list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });
    const out = await response.json();
    if (out.statusCode === 400)
      messagingContext.addMessage({
        status: "error",
        text: out.responseData.message,
      });
    if (out.statusCode === 200) {
      messagingContext.addMessage({
        status: "success",
        text: "Thank You!",
      });
      setSuccess(true);
      setEmail("");
      setValid(false);
    }
    setLoading(false);
  };

  return (
    <div className="min-w-screen min-h-screen flex items-center justify-center flex-col gap-1 bg-primary p-8">
      <h1 className="font-console text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
        exceptra
      </h1>
      <h3 className="text-pink-500 font-console">
        Next-gen architecture, system and application-level monitoring and
        insights.
      </h3>
      <div className="max-w-[400px]">
        <h5 className="mt-4 text-white font-console font-light text-center">
          Get notified when we launch to get a free API key and access to our
          Slack integration.
        </h5>
        <div className="bg-[#333] px-1 py-1 rounded-full mt-3 flex gap-2">
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (e.target.value?.length > 6 && e.target.value.includes("@")) {
                setValid(true);
              } else {
                setValid(false);
              }
            }}
            className="bg-transparent pl-4 font-console font-light text-lg flex-1 text-white/80 outline-none"
            placeholder="email@example.com"
          ></input>
          <button
            onClick={() => mockSubmit()}
            disabled={loading || !valid}
            className={`h-8 w-24  font-console px-4 rounded-full ${
              loading || !valid
                ? "bg-[#111]/50 text-white/30"
                : "hover:bg-purple-800 bg-[#111] text-white"
            } transition`}
          >
            {loading ? <span className="animate-pulse">o o o</span> : "Submit"}
          </button>
        </div>
        {success && (
          <p className="text-center mt-2 font-console text-xs text-pink-500/70">
            Nice! We will contact you shortly once we launch! 🚀
          </p>
        )}
      </div>
    </div>
  );
}
